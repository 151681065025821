import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../home.css'
import { SvgTeam } from './SvgTeam'
import { useGlobalContext } from './Context'
import { ref, onValue, off } from 'firebase/database'
import { database } from '../firebaseConfig'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SvgRmu } from './SvgRmu'

export default function Handler() {
	const {
		isLoading,
		setIsLoading,
		copy,
		setCopy,
		setPostDone,
		postDone,
		url,
		setUrl,
		selected,
		link,
		setLink,
		landscape,
		setLandscape,
		stats,
		setStats,
		setMatchId,
		setCompId,
		playSite,
		setPlaySite,
		setChallenge,
	} = useGlobalContext()

	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const compId = searchParams.get('compId')
	const matchId = searchParams.get('matchId')
	const isLandscape = searchParams.get('isLandscape')
	const challenge = searchParams.get('challenge')
	const challengeId = searchParams.get('challengeId')


	useEffect(() => {
		setChallenge(challenge)
		setLandscape(isLandscape)
		setCompId(compId)
		if (challenge) {
			setMatchId(challengeId)
		} else setMatchId(matchId)
		console.log(isLandscape)
	}, [compId, matchId, challenge, isLandscape])

	const liveReq = () => {
		axios
			.post(
				`https://scrbd.co-stream.live/api/livescores?compId=${compId}&matchId=${matchId}`,
				null,
				{
					params: { matchId, compId },
				}
			)
			.then((response) => {
				const res = Object.keys(response.data).map((key) => response.data[key])
				setStats(res)
			})
			.catch((err) => console.warn(err))
	}

	// const testReq = () => {
	// 	axios
	// 		.post(
	// 			`https://scrbd.co-stream.live/api/playsite?compId=${compId}&matchId=${matchId}`,
	// 			null,
	// 			{
	// 				params: { matchId, compId },
	// 			}
	// 		)
	// 		.then((response) => {
	// 			const res = Object.keys(response.data).map((key) => response.data[key])
	// 			setStats(res)
	// 		})
	// 		.catch((err) => console.warn(err))
	// }

	useEffect(() => {
		// Call the function immediately
		// if (playSite === 'false' || playSite == null) {
		// 	testReq()
		// } else if ((!playSite && challenge === 'false') || (!playSite && challenge == null)) {
		if (!challenge) {
			liveReq()
		}
		// }

		// Then set an interval for every 15 seconds
		const interval = setInterval(() => {
			// if (playSite === 'false' || playSite == null) {
			// 	testReq()
			// } else if ((!playSite && challenge === 'false') || (!playSite && challenge == null)) {
			if (!challenge) {
				liveReq()
			}
			// }
		}, 15000)

		return () => {
			clearInterval(interval)
		}
	}, [matchId, compId, challenge, isLandscape])

	// useEffect( () =>
	// {
	// 	const databaseRef = ref( database, `matches/${ matchId }/landscape` );

	// 	const unsubscribe = onValue( databaseRef, ( snapshot ) =>
	// 	{
	// 		const data = snapshot.val();
	// 		if ( data != null )
	// 		{
	// 			setLandscape( data );
	// 		}
	// 	} );

	// 	return () => off( databaseRef, 'value', unsubscribe );
	// }, [ matchId ] );

	const [animationClass, setAnimationClass] = useState('')

	useEffect(() => {
		setAnimationClass('container-exit')
		const timeout = setTimeout(() => setAnimationClass('container-enter'), 500) // Matches transition timing

		return () => clearTimeout(timeout)
	}, [landscape])

	useEffect(() => {
		console.log('Current landscape value:', landscape)
	}, [landscape])

	return (
		<>
			<Helmet>
				<style>
					{
						'body { margin: 0; padding: 0; overflow: hidden; background-image: none; background-color: transparent !important; }'
					}
				</style>
			</Helmet>
			<div
				className={`container ${landscape === 'true' ? 'container-3' : 'container-4'
					} ${animationClass}`}
			>
				{landscape === 'false' ? (
					<SvgTeam />
				) : (
					// <SvgRmu />

					<SvgRmu />
				)}
			</div>
		</>
	)
}
