import React from 'react'
import { useState, useEffect, useCallback, useLayoutEffect, useRef, useMemo } from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import '../home.css'
import { useGlobalContext } from './Context'
import throttle from 'lodash/throttle'
import 'typeface-inter'
import { useMQTT } from "./useMQTT"
import { getPlayerData } from './Supabase'

const SvgTeam = () => {
	const { stats, matchId, compId, challenge } = useGlobalContext()
	const [framesText, setFramesText] = useState(null)
	const [p1Text, setp1Text] = useState(null)
	const [p2Text, setp2Text] = useState(null)
	const [venueNameText, setVenueNameText] = useState(null)
	const [compNameText, setCompNameText] = useState(null)
	const [framesLeft, setFramesLeft] = useState(null)
	const [framesMax, setFramesMax] = useState(null)
	const [p1Max, setP1Max] = useState(null)
	const [p2Max, setP2Max] = useState(null)
	const [venueMax, setVenueMax] = useState(null)
	const [compMax, setCompMax] = useState(null)


	const [extension, setExtension] = useState(30)
	const [shortTimer, setShortTimer] = useState(40)
	const [longTimer, setLongTimer] = useState(60)
	const [selectedController, setSelectedController] = useState("shortTimer")
	const [clockStatus, setClockStatus] = useState("Stopped")
	const [isPlaying, setIsPlaying] = useState(false)
	const [time, setTime] = useState(shortTimer)
	const [extUsed, setExtUsed] = useState(false)
	const [progressBarPercentage, setProgressBarPercentage] = useState(time)
	const minutesToDisplay = Math.floor(time / 60)
	const secondsToDisplay = time - minutesToDisplay * 60
	const [newFrame, setNewFrame] = useState(false)

	const [homeTeamName, setHomeTeamName] = useState('')
	const [awayTeamName, setAwayTeamName] = useState('')
	const [homeTeamScore, setHomeTeamScore] = useState('')
	const [awayTeamScore, setAwayTeamScore] = useState('')
	const [cdata, setCdata] = useState({})

	useEffect(() => {
		async function fetchPlayerData() {
			if (challenge) {
				const data = await getPlayerData(matchId)
				setCdata(data)
			}
		}
		fetchPlayerData()
	}, [challenge, matchId])

	const brokerUrl =
		process.env.REACT_APP_MQTT_BROKER_URL

	const shouldConnectMQTT = Boolean(matchId && challenge)

	// Stabilize the topics array conditionally
	const topics = useMemo(() => shouldConnectMQTT ? [
		`match/${matchId}/timer`,
		`match/${matchId}/scoreboard`,
	] : [], [matchId, shouldConnectMQTT])

	// Conditional MQTT initialization
	const { messages } = useMQTT(
		shouldConnectMQTT ? brokerUrl : null,
		topics
	)

	// Track the last processed message
	const lastMessageRef = useRef(null)

	useEffect(() => {
		if (!shouldConnectMQTT || messages.length === 0) return

		const newMsg = messages[messages.length - 1]
		if (lastMessageRef.current === newMsg) {
			return
		}
		lastMessageRef.current = newMsg

		try {
			const payload = newMsg.payload
			console.log(payload)

			if (newMsg.topic.includes("scoreboard")) {
				if (payload.homescore !== undefined)
					setHomeTeamScore(payload.homescore)
				if (payload.awayscore !== undefined)
					setAwayTeamScore(payload.awayscore)
				if (payload.homeTeamName !== undefined)
					setHomeTeamName(payload.homeTeamName)
				if (payload.awayTeamName !== undefined)
					setAwayTeamName(payload.awayTeamName)
			}
			if (newMsg.topic.includes("timer")) {
				if (payload.newFrame !== undefined) setNewFrame(payload.newFrame)
				if (payload.clockStatus !== undefined) setClockStatus(payload.clockStatus)
				if (payload.selectedController !== undefined) setSelectedController(payload.selectedController)
				if (payload.extUsed !== undefined) setExtUsed(payload.extUsed)
				if (payload.isPlaying !== undefined) setIsPlaying(payload.isPlaying)
				if (payload.reset) {
					setTime(payload.reset)
					setProgressBarPercentage(payload.reset)
				}
				if (payload.extUsed === true) {
					setTime((prev) => prev + extension)
					setProgressBarPercentage((prev) => prev + extension)
				}
				if (payload.time !== undefined) setTime(payload.time)
				if (payload.progressBarPercentage !== undefined) setProgressBarPercentage(payload.progressBarPercentage)
			}
		} catch (err) {
			console.error("Error parsing MQTT message:", err)
		}
	}, [messages, shouldConnectMQTT])

	useEffect(() => {
		if (newFrame === true) {
			setTimeout(() => {
				setNewFrame(false)
			}, 1000)
		}
	}, [newFrame])

	function useInterval(callback, delay) {
		const savedCallback = useRef()

		useEffect(() => {
			savedCallback.current = callback
		}, [callback])

		useEffect(() => {
			function tick() {
				savedCallback.current()
			}
			if (delay !== null && isPlaying) {
				let id = setInterval(tick, delay)
				return () => clearInterval(id)
			}
		}, [delay])
	}

	const twoDigits = (num) => String(num).padStart(2, "0")
	const oneDigits = (num) => String(num).padStart(2, "0")
	const timeText =
		time >= 60
			? `${minutesToDisplay}:${twoDigits(secondsToDisplay)}`
			: twoDigits(secondsToDisplay)


	useInterval(
		() => {
			if (time > 0) {
				setTime((prevTime) => prevTime - 1)
			} else {
				setClockStatus("Stopped")
			}
		},
		clockStatus === 'Started' ? 1000 : null
	)

	const framesRef = useRef(null)
	const p1TextRef = useRef(null)
	const awayTeamLabelRef = useRef(null)
	const venueNameRef = useRef(null)
	const compNameRef = useRef(null)

	const calculateFramesLeft = 18 - (stats[0]?.homescore + stats[0]?.awayscore)

	useEffect(() => {
		if (stats[0]?.matchformat === 'Play 0') {
			setFramesLeft(calculateFramesLeft)
		}
	}, [stats[0]?.homescore, stats[0]?.awayscore])

	const framesLength = 175
	const p1Length = 430
	const p2Length = 430
	const venueLength = 400
	const compLength = 400

	const calculateTextLength = () => {
		// For frames:
		if (framesRef.current) {
			const computedLength = framesRef.current.getComputedTextLength()
			const ctm = framesRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = framesLength / scaleX
			console.log('Max user units for frames:', maxLength)
			setFramesMax(maxLength)

			const pixelLength = computedLength * scaleX
			if (pixelLength > framesLength) {
				console.log(
					'Frames text is too long. It spans',
					pixelLength,
					'pixels, but container is only',
					framesLength,
					'pixels wide.'
				)
			} else {
				console.log('Frames text fits within the container.')
			}
			setFramesText(computedLength > maxLength ? maxLength : null)
		}

		// For p1Text:
		if (p1TextRef.current) {
			const computedLength = p1TextRef.current.getComputedTextLength()
			const ctm = p1TextRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = p1Length / scaleX
			console.log('Max user units for p1Text:', maxLength)
			setP1Max(maxLength)

			const pixelLength = computedLength * scaleX
			if (pixelLength > p1Length) {
				console.log(
					'p1Text is too long. It spans',
					pixelLength,
					'pixels, but container is only',
					p1Length,
					'pixels wide.'
				)
			} else {
				console.log('p1Text fits within the container.')
			}
			setp1Text(computedLength > maxLength ? maxLength : null)
		}

		// For awayTeamLabel (p2Text):
		if (awayTeamLabelRef.current) {
			const computedLength = awayTeamLabelRef.current.getComputedTextLength()
			const ctm = awayTeamLabelRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = p2Length / scaleX
			console.log('Max user units for p2Text:', maxLength)
			setP2Max(maxLength)

			const pixelLength = computedLength * scaleX
			if (pixelLength > p2Length) {
				console.log(
					'p2Text is too long. It spans',
					pixelLength,
					'pixels, but container is only',
					p2Length,
					'pixels wide.'
				)
			} else {
				console.log('p2Text fits within the container.')
			}
			setp2Text(computedLength > maxLength ? maxLength : null)
		}

		// For venueName:
		if (venueNameRef.current) {
			const computedLength = venueNameRef.current.getComputedTextLength()
			const ctm = venueNameRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = venueLength / scaleX
			console.log('Max user units for venueName:', maxLength)
			setVenueMax(maxLength)

			const pixelLength = computedLength * scaleX
			if (pixelLength > venueLength) {
				console.log(
					'Text is too long. It spans',
					pixelLength,
					'pixels, but container is only',
					venueLength,
					'pixels wide.'
				)
			} else {
				console.log('Text fits within the container.')
			}
			setVenueNameText(computedLength > maxLength ? maxLength : null)
		}

		// For compName:
		if (compNameRef.current) {
			const computedLength = compNameRef.current.getComputedTextLength()
			const ctm = compNameRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = compLength / scaleX
			console.log('Max user units for compName:', maxLength)
			setCompMax(maxLength)

			const pixelLength = computedLength * scaleX
			if (pixelLength > compLength) {
				console.log(
					'Text is too long. It spans',
					pixelLength,
					'pixels, but container is only',
					compLength,
					'pixels wide.'
				)
			} else {
				console.log('Text fits within the container.')
			}
			setCompNameText(computedLength > maxLength ? maxLength : null)
		}
	}

	useLayoutEffect(() => {
		calculateTextLength()
	}, [stats, challenge, matchId, compId])

	const calculateProgress = () => {
		if (!stats[0]?.timer || !stats[0]?.totalTime) return 0
		return (stats.timer / stats.totalTime) * 100
	}


	return (
		<svg xmlns='http://www.w3.org/2000/svg' data-name='Layer 2' viewBox='0 0 1304.59 120'>
			<defs>
				<linearGradient
					id='a'
					x1={486129.53}
					x2={551770.06}
					y1={-29575.89}
					y2={-33586.07}
					gradientTransform='matrix(1 0 0 -1 0 122.01)'
					gradientUnits='userSpaceOnUse'>
					<stop offset={0} stopColor='#f7f5f5' />
					<stop offset={0.54} stopColor='#d9d8d8' />
				</linearGradient>
			</defs>
			<g data-name='Layer 2'>
				<g data-name='Layer 2-2'>
					<path d='M16.13 19.49c2.14-3.79 6.11-6.1 10.4-6.1h1266c9.41 0 15.19 10.23 10.24 18.15l-14.53 23.93c-2.14 3.63-6.11 5.78-10.24 5.78H13.16c-9.25.17-15.02-9.74-10.4-17.66l13.38-23.93v-.17h-.01Z' />
					<path
						d='M16.79 20.97c2.14-3.63 6.11-5.94 10.4-5.94h539.55v44.73H14.98c-9.42 0-15.03-10.07-10.4-17.99L16.8 20.98Zm549.96-5.94h723.97c9.41 0 15.19 10.4 10.24 18.32l-13.04 20.79c-2.14 3.46-6.11 5.61-10.24 5.61H566.75V15.02Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path d='M551.39 61.41h188.87c0 8.26-5.45 15.02-12.22 15.02H563.6c-6.77 0-12.22-6.77-12.22-15.02Z' />
					<path
						d='M553.04 59.76h185.57c0 8.26-5.45 15.02-12.06 15.02H564.92c-6.6 0-12.06-6.77-12.06-15.02h.18Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path d='M740.27 15.03H551.4c0-8.26 5.45-15.02 12.22-15.02h164.44c6.77 0 12.22 6.77 12.22 15.02Z' />
					<path
						d='M738.62 15.03H553.05c0-7.42 5.45-13.54 12.06-13.54h161.63c6.6 0 12.06 6.1 12.06 13.54h-.18Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path
						d='M569.06 4.47h153.55c6.6 0 12.06 6.1 12.06 13.54v40.43c0 7.42-5.45 13.54-12.06 13.54H569.06c-6.6 0-12.06-6.1-12.06-13.54V18c0-7.42 5.45-13.54 12.06-13.54Z'
						style={{
							fill: 'url(#a)',
						}}
					/>
					<path
						d='M17.29 20.97c2.14-3.79 6.11-6.1 10.4-6.1h1264.19c7.93 0 13.54 7.76 11.06 15.18-.33.99-1.15 1.65-2.14 1.65H11.01l6.11-10.73h.17Z'
						style={{
							fill: '#fff',
							isolation: 'isolate',
							opacity: 0.1,
						}}
					/>
					<path d='M557.5 83.03h174.35v36.81H557.5V83.03Z' />
					<path
						d='M559.15 84.52h171.21v33.67H559.15V84.52Z'
						style={{
							fill: '#fff',
						}}
					/>
					<path
						d='M509.46 94.91c0-6.6 5.45-12.05 12.06-12.05h35.99v36.81h-35.99c-6.6 0-12.06-5.45-12.06-12.05V94.75v.17Z'
						style={{
							fill: extUsed ? '#d70e1b' : '#42d70e',
						}}
					/>
					<path
						d='M731.84 83.03h52.01c6.6 0 12.06 5.45 12.06 12.05v12.87c0 6.6-5.45 12.05-12.06 12.05h-52.01V83.03Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path
						d='M509.46 99.7c0-9.24 7.59-16.83 16.84-16.83h257.56c6.6 0 12.06 5.45 12.06 12.05v4.78H509.46Z'
						style={{
							fill: '#fff',
							isolation: 'isolate',
							opacity: 0.1,
						}}
					/>
					<path
						d='m512.59 108.62 2.48-15.18h10.57l-.5 2.97h-6.94l-.5 3.14h6.27l-.5 2.97h-6.27l-.5 3.14h6.94l-.5 2.97h-10.57.02Zm18.83-15.19 1.98 4.78 3.63-4.78h4.3l-5.78 7.59 3.46 7.59h-4.13l-1.98-4.78-3.63 4.78h-4.3l5.94-7.59-3.3-7.59h3.96-.17.02Zm10.89 2.97.5-2.97h12.71l-.5 2.97h-4.62l-1.98 12.22h-3.63l1.98-12.22h-4.62.17-.01Z'
						style={{
							fill: '#fff',
						}}
					/>
					<path d='M12.99 78.41c1.98-4.13 6.27-6.77 10.73-6.77h426.79c9.25 0 15.02 9.9 10.4 17.99l-13.7 23.93c-2.14 3.79-6.11 6.1-10.4 6.1H12c-8.91 0-14.7-9.24-10.73-17.33L12.99 78.4Z' />
					<path
						d='M14.98 81.54c1.98-3.96 6.11-6.6 10.73-6.6h175.01v41.59H12.83c-7.1 0-11.89-7.59-8.58-14.03l10.73-21.13v.17Zm185.74-6.6h248.64c7.26 0 11.89 7.76 8.42 14.19l-11.56 21.13c-2.14 3.79-6.11 6.27-10.57 6.27H200.71V74.94h.01Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path
						d='M14.65 82.04c1.98-4.29 6.27-7.1 10.9-7.1h422.82c8.09 0 13.04 9.08 8.58 15.85-.33.5-.99.99-1.65.99H10.35l4.46-9.74h-.17Z'
						style={{
							fill: '#fff',
							isolation: 'isolate',
							opacity: 0.1,
						}}
					/>
					<path d='M846.59 78.41c1.98-4.13 6.27-6.77 10.73-6.77h426.79c9.25 0 15.02 9.9 10.4 17.99l-13.7 23.93c-2.14 3.79-6.11 6.1-10.4 6.1H845.44c-8.91 0-14.7-9.24-10.73-17.33l11.72-23.93h.17-.01Z' />
					<path
						d='M848.57 81.54c1.98-3.96 6.11-6.6 10.73-6.6h175.01v41.59H846.43c-7.1 0-11.89-7.59-8.58-14.03l10.73-21.13v.17Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path
						d='M1034.31 74.94h248.64c7.26 0 11.89 7.76 8.42 14.19l-11.56 21.13c-2.14 3.79-6.11 6.27-10.57 6.27H1034.3V74.94h.01Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path
						d='M848.25 82.04c1.98-4.29 6.27-7.1 10.9-7.1h422.82c8.09 0 13.04 9.08 8.58 15.85-.33.5-.99.99-1.65.99H843.95l4.46-9.74h-.17.01ZM551.72 11.89c0-6.6 8.09-12.22 10.57-11.88h166.75c1.15 0 9.58 4.29 10.9 10.56.5 2.81.33 4.46.33 4.46H551.72l.33-3.14h-.33Z'
						style={{
							fill: '#fff',
							isolation: 'isolate',
							opacity: 0.1,
						}}
					/>
					<path
						d='M1059.78 91.78h10v10h-10z'
						data-name='center right'
						style={{
							fill: 'none',
						}}
					/>
					<path
						d='M226.27 90.65h10v10h-10z'
						data-name='center left'
						style={{
							fill: 'none',
						}}
					/>
					<path
						d='M271.61 32.31h10v10h-10z'
						data-name='center player 1'
						style={{
							fill: 'none',
						}}
					/>
					<path
						d='M1010.07 31.7h10v10h-10z'
						data-name='center player 2'
						style={{
							fill: 'none',
						}}
					/>
				</g>
			</g>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 46,
					fill: 'white',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 775 53)'>
				<tspan x={0} y={0}>
					{challenge ? awayTeamScore : stats[0]?.awayscore}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 46,
					fill: 'white',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 513 53)'>
				<tspan x={0} y={0}>
					{challenge ? homeTeamScore : stats[0]?.homescore}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 30,
					fill: 'white',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 760 113)'>
				<tspan x={0} y={0}>
					{challenge === 'true' || challenge === true ? timeText : '0:00'}
				</tspan>
			</text>
			<text
				ref={p1TextRef}
				style={{
					isolation: 'isolate',
					fontSize: 38,
					fill: '#fff',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				id='p1Text'
				transform='matrix(.7 0 0 1 240 49)'
				textLength={p1Text}
				lengthAdjust='spacingAndGlyphs'>
				<tspan x={0} y={0}>
					{challenge ? cdata?.homeTeam?.name : stats[0]?.hometeamlabel}
				</tspan>
			</text>
			<text
				ref={awayTeamLabelRef}
				style={{
					isolation: 'isolate',
					fontSize: 38,
					fill: '#fff',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 1050 49)'
				textLength={p2Text}
				lengthAdjust='spacingAndGlyphs'
				id='awayTeamLabel'>
				<tspan x={0} y={0}>
					{challenge ? cdata?.awayTeam?.name : stats[0]?.awayteamlabel}
				</tspan>
			</text>
			<text
				ref={venueNameRef}
				style={{
					isolation: 'isolate',
					fontSize: 32,
					fill: '#fff',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					textAlign: 'center',
					fontVariant: 'small-caps',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 227 107)'
				textLength={venueNameText}
				lengthAdjust='spacingAndGlyphs'
				id='venueName'>
				<tspan x={0} y={0}>
					{cdata?.break_type !== undefined ? `${cdata?.break_type} breaks` : stats[0]?.venuename}
				</tspan>
			</text>
			<text
				ref={compNameRef}
				style={{
					isolation: 'isolate',
					fontSize: 32,
					fill: '#fff',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					fontVariant: 'small-caps',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 1065 107)'
				textLength={compNameText}
				lengthAdjust='spacingAndGlyphs'
				id='compName'>
				<tspan x={0} y={0}>
					{challenge === 'true' || challenge === true ? cdata?.discipline : stats[0]?.compname}
				</tspan>
			</text>
			{stats[0]?.matchformat === 'Play 0' ? (
				<>
					<text
						ref={framesRef}
						style={{
							isolation: 'isolate',
							fontSize: 51,
							fill: '#231f20',
							fontFamily: 'Roboto',
							fontWeight: 'bold',
							textAlign: 'center',
							fontVariant: 'small-caps',
							verticalAlign: 'middle',
							textAnchor: 'middle',
						}}
						transform='matrix(.7 0 0 1 644 68)'
						id='frames'
						textLength={framesText}
						lengthAdjust='spacingAndGlyphs'>
						{challenge === 'true' || challenge === true
							? cdata?.race_length ?? ''
							: stats[0]?.matchformat === 'Play 0'
								? framesLeft
								: ''}
					</text>
					<text
						style={{
							isolation: 'isolate',
							fontSize: 44,
							fill: '#231f20',
							fontFamily: 'Roboto',
							fontWeight: 'bold',
							fontVariant: 'small-caps',
							textAlign: 'center',
							verticalAlign: 'middle',
							textAnchor: 'middle',
						}}
						transform='matrix(.7 0 0 1 644 26)'
						id='frames-label'
						textLength={framesText}
						lengthAdjust='spacingAndGlyphs'>
						{stats[0]?.matchformat === 'Play 0' ? 'Frames Left' : ''}
					</text>
				</>
			) : (
				<text
					ref={framesRef}
					style={{
						isolation: 'isolate',
						fontSize: 40,
						fill: '#231f20',
						fontFamily: 'Roboto',
						fontWeight: 'bold',
						textAlign: 'center',
						verticalAlign: 'middle',
						fontVariant: 'small-caps',
						textAnchor: 'middle',
					}}
					transform='matrix(.7 0 0 1 644 50)'
					id='frames'
					textLength={framesText}
					lengthAdjust='spacingAndGlyphs'>
					{cdata?.race_length ?? '' ? `( ${cdata?.race_length} )` : stats[0]?.matchformat}
				</text>
			)}
			<foreignObject x='558' y='83' width='174' height='36.5'>
				<div
					style={{
						width: '174px',
						height: '36.5px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						overflow: 'visible',
						zIndex: 1000,
						position: 'relative',
					}}>
					<ProgressBar
						completed={time ? (time / progressBarPercentage) * 100 : 0}
						// customLabel={`${Math.ceil(totalTime - time)}s`} // Show remaining time
						customLabel={' '}
						labelSize='30'
						labelColor='#fff'
						labelAlignment='center'
						height='36.5px'
						width='174px'
						borderRadius='2'
						baseBgColor='#fff'
						bgColor='red'
						isLabelVisible={false}
						transitionDuration='1s'
						transitionTimingFunction='ease-in-out'
						animateOnRender={true}
						dir='ltr'
						isIndeterminate={false}
					/>
				</div>
			</foreignObject>
		</svg>
	)
}
// {stats[0]?.awayframepointsadj===0 && stats[0]?.awayscorepoints===0 ? stats?.awayscore : `${awayScore}`}
// {stats[0]?.homeframepointsadj===0 && stats[0]?.homescorepoints===0 ? stats?.homescore : `${homeScore}`}
// {stats[0]?.homescorepoints>0 ? `${stats[0]?.homescore}` : ''}
// {stats[0]?.awayscorepoints>0 ? `${stats[0]?.awayscore}` : ''}
export { SvgTeam }
