import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export async function getPlayerData(challengeId) {
    const { data, error } = await supabase
        .from('challenges')
        .select('*')
        .eq('challengeid', challengeId)
        .single()

    if (error) {
        console.error('Error fetching player data:', error)
        return null
    }
    return data
}