// useMQTT.js
import { useState, useEffect, useRef } from "react"
import mqtt from "mqtt"
import { Buffer } from "buffer"
import process from "process"

global.Buffer = global.Buffer || Buffer
global.process = process

export const useMQTT = (brokerUrl, topics = []) => {
    const [client, setClient] = useState(null)
    const [messages, setMessages] = useState([])
    const mqttClientRef = useRef(null)
    const topicsRef = useRef(topics)

    useEffect(() => {
        topicsRef.current = topics
    }, [topics])

    useEffect(() => {
        if (!brokerUrl) {
            console.warn("MQTT broker URL is not set. MQTT connection skipped.")
            return
        }

        const mqttClient = mqtt.connect(brokerUrl, {
            wsOptions: {
                protocols: ["mqtt"],
            },
        })
        mqttClientRef.current = mqttClient

        mqttClient.on("connect", () => {
            console.log("Connected to MQTT broker:", brokerUrl)
            topicsRef.current.forEach((topic) => {
                mqttClient.subscribe(topic, (err) => {
                    if (err) {
                        console.error(`Subscription error for topic ${topic}:`, err)
                    } else {
                        console.log(`Subscribed to topic: ${topic}`)
                    }
                })
            })
        })

        mqttClient.on("reconnect", () => {
            console.log("Reconnecting to MQTT broker...")
        })

        mqttClient.on("error", (error) => {
            console.error("MQTT Error:", error)
        })

        mqttClient.on("message", (topic, message) => {
            let payload
            try {
                payload = JSON.parse(message.toString())
            } catch (err) {
                console.error("Error parsing MQTT message as JSON:", err)
                payload = message.toString()
            }
            setMessages((prev) => [
                ...prev,
                { topic, payload, receivedAt: Date.now() }
            ])
        })

        setClient(mqttClient)

        return () => {
            mqttClient.end(true)
            mqttClientRef.current = null
            console.log("Disconnected from MQTT broker")
        }
    }, [brokerUrl])

    const publishMessage = (topic, message) => {
        if (mqttClientRef.current && mqttClientRef.current.connected) {
            mqttClientRef.current.publish(topic, message)
        } else {
            console.error("MQTT client not connected, cannot publish message.")
        }
    }

    return { messages, publishMessage }
}
