import "../home.css"
import React, {
	useState,
	useEffect,
	useLayoutEffect,
	useCallback,
	useRef,
	useMemo,
} from "react"
import { useGlobalContext } from "./Context" // adjust as needed
import ProgressBar from "@ramonak/react-progress-bar"
import throttle from "lodash/throttle" // or your own throttle implementation
import { useMQTT } from "./useMQTT" // Import our custom hook
import { getPlayerData } from './Supabase'


const SvgRmu = () => {
	const { stats, matchId, compId, challenge } = useGlobalContext()
	const [framesText, setFramesText] = useState(null)
	const [p1Text, setP1Text] = useState(null)
	const [p2Text, setP2Text] = useState(null)
	const [venueNameText, setVenueNameText] = useState(null)
	const [compNameText, setCompNameText] = useState(null)
	const [framesLeft, setFramesLeft] = useState(null)
	const [timezoneText, setTimezoneText] = useState(null)
	const [framesMax, setFramesMax] = useState(null)
	const [p1Max, setP1Max] = useState(null)
	const [p2Max, setP2Max] = useState(null)
	const [venueMax, setVenueMax] = useState(null)
	const [compMax, setCompMax] = useState(null)
	const [timezoneMax, setTimezoneMax] = useState(null)

	const [selectedController, setSelectedController] = useState("shortTimer")
	const [extension, setExtension] = useState(30)
	const [shortTimer, setShortTimer] = useState(40)
	const [longTimer, setLongTimer] = useState(60)
	const [clockStatus, setClockStatus] = useState("Stopped")
	const [isPlaying, setIsPlaying] = useState(false)
	const [time, setTime] = useState(shortTimer)
	const [extUsed, setExtUsed] = useState(false)
	const [progressBarPercentage, setProgressBarPercentage] = useState(time)
	const minutesToDisplay = Math.floor(time / 60)
	const secondsToDisplay = time - minutesToDisplay * 60
	const [newFrame, setNewFrame] = useState(false)

	const [homeTeamName, setHomeTeamName] = useState('')
	const [awayTeamName, setAwayTeamName] = useState('')
	const [homeTeamScore, setHomeTeamScore] = useState('')
	const [awayTeamScore, setAwayTeamScore] = useState('')
	const [cdata, setCdata] = useState({})

	useEffect(() => {
		async function fetchPlayerData() {
			if (challenge) {
				const data = await getPlayerData(matchId)
				setCdata(data)
			}
		}
		fetchPlayerData()
	}, [challenge, matchId])

	const brokerUrl = process.env.REACT_APP_MQTT_BROKER_URL

	const shouldConnectMQTT = Boolean(matchId && challenge)

	// Stabilize the topics array conditionally
	const topics = useMemo(() => shouldConnectMQTT ? [
		`match/${matchId}/timer`,
		`match/${matchId}/scoreboard`,
	] : [], [matchId, shouldConnectMQTT])

	// Conditional MQTT initialization
	const { messages } = useMQTT(
		shouldConnectMQTT ? brokerUrl : null,
		topics
	)

	// Track the last processed message
	const lastMessageRef = useRef(null)

	useEffect(() => {
		if (!shouldConnectMQTT || messages.length === 0) return

		const newMsg = messages[messages.length - 1]
		if (lastMessageRef.current === newMsg) {
			return
		}
		lastMessageRef.current = newMsg

		try {
			const payload = newMsg.payload
			console.log(payload)

			if (newMsg.topic.includes("scoreboard")) {
				if (payload.homescore !== undefined)
					setHomeTeamScore(payload.homescore)
				if (payload.awayscore !== undefined)
					setAwayTeamScore(payload.awayscore)
				if (payload.homeTeamName !== undefined)
					setHomeTeamName(payload.homeTeamName)
				if (payload.awayTeamName !== undefined)
					setAwayTeamName(payload.awayTeamName)
			}
			if (newMsg.topic.includes("timer")) {
				if (payload.newFrame !== undefined) setNewFrame(payload.newFrame)
				if (payload.clockStatus !== undefined) setClockStatus(payload.clockStatus)
				if (payload.selectedController !== undefined) setSelectedController(payload.selectedController)
				if (payload.extUsed !== undefined) setExtUsed(payload.extUsed)
				if (payload.isPlaying !== undefined) setIsPlaying(payload.isPlaying)
				if (payload.reset) {
					setTime(payload.reset)
					setProgressBarPercentage(payload.reset)
				}
				if (payload.extUsed === true) {
					setTime((prev) => prev + extension)
					setProgressBarPercentage((prev) => prev + extension)
				}
				if (payload.time !== undefined) setTime(payload.time)
				if (payload.progressBarPercentage !== undefined) setProgressBarPercentage(payload.progressBarPercentage)
			}
		} catch (err) {
			console.error("Error parsing MQTT message:", err)
		}
	}, [messages, shouldConnectMQTT])

	useEffect(() => {
		if (newFrame === true) {
			setTimeout(() => {
				setNewFrame(false)
			}, 1000)
		}
	}, [newFrame])

	function useInterval(callback, delay) {
		const savedCallback = useRef()

		useEffect(() => {
			savedCallback.current = callback
		}, [callback])

		useEffect(() => {
			function tick() {
				savedCallback.current()
			}
			if (delay !== null && isPlaying) {
				let id = setInterval(tick, delay)
				return () => clearInterval(id)
			}
		}, [delay])
	}

	const twoDigits = (num) => String(num).padStart(2, "0")
	const oneDigits = (num) => String(num).padStart(2, "0")
	const timeText =
		time >= 60
			? `${minutesToDisplay}:${twoDigits(secondsToDisplay)}`
			: twoDigits(secondsToDisplay)


	useInterval(
		() => {
			if (time > 0) {
				setTime((prevTime) => prevTime - 1)
			} else {
				setClockStatus("Stopped")
			}
		},
		clockStatus === "Started" ? 1000 : null
	)

	// Calculate framesLeft from stats (example logic)
	const calculateFramesLeft = stats[0]?.homescore + stats[0]?.awayscore
	useEffect(() => {
		if (stats[0]?.matchformat === "Play 0") {
			setFramesLeft(calculateFramesLeft)
		}
	}, [stats[0]?.homescore, stats[0]?.awayscore])

	// Create refs for measuring text elements
	const timezoneRef = useRef(null)
	const framesRef = useRef(null)
	const p1FrameRef = useRef(null)
	const p1ScoreRef = useRef(null)
	const p2FrameRef = useRef(null)
	const p2ScoreRef = useRef(null)
	const homeTeamLabelRef = useRef(null)
	const awayTeamLabelRef = useRef(null)
	const venueNameRef = useRef(null)
	const compNameRef = useRef(null)

	const framesLength = 220
	const p1Length = 700
	const p2Length = 700
	const venueLength = 500
	const compLength = 550
	const timezoneLength = 380

	const calculateTextLength = () => {
		// For timezone text:
		if (timezoneRef.current) {
			const computedLength = timezoneRef.current.getComputedTextLength()
			const ctm = timezoneRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = timezoneLength / scaleX
			setTimezoneMax(maxLength)
			const pixelLength = computedLength * scaleX
			if (pixelLength > timezoneLength) {
				console.log(
					"timezone text is too long. It spans",
					pixelLength,
					"pixels, but container is only",
					timezoneLength,
					"pixels wide."
				)
			} else {
				console.log("timezone text fits within the container.")
			}
			setTimezoneText(computedLength > maxLength ? maxLength : null)
		}

		// For frames:
		if (framesRef.current) {
			const computedLength = framesRef.current.getComputedTextLength()
			const ctm = framesRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = framesLength / scaleX
			setFramesMax(maxLength)
			const pixelLength = computedLength * scaleX
			if (pixelLength > framesLength) {
				console.log(
					"Frames text is too long. It spans",
					pixelLength,
					"pixels, but container is only",
					framesLength,
					"pixels wide."
				)
			} else {
				console.log("Frames text fits within the container.")
			}
			setFramesText(computedLength > maxLength ? maxLength : null)
		}

		// For p1Text:
		if (p1FrameRef.current) {
			const computedLength = p1FrameRef.current.getComputedTextLength()
			const ctm = p1FrameRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = p1Length / scaleX
			setP1Max(maxLength)
			const pixelLength = computedLength * scaleX
			if (pixelLength > p1Length) {
				console.log(
					"p1Text is too long. It spans",
					pixelLength,
					"pixels, but container is only",
					p1Length,
					"pixels wide."
				)
			} else {
				console.log("p1Text fits within the container.")
			}
			setP1Text(computedLength > maxLength ? maxLength : null)
		}

		// For awayTeamLabel (p2Text):
		if (awayTeamLabelRef.current) {
			const computedLength = awayTeamLabelRef.current.getComputedTextLength()
			const ctm = awayTeamLabelRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = p2Length / scaleX
			setP2Max(maxLength)
			const pixelLength = computedLength * scaleX
			if (pixelLength > p2Length) {
				console.log(
					"p2Text is too long. It spans",
					pixelLength,
					"pixels, but container is only",
					p2Length,
					"pixels wide."
				)
			} else {
				console.log("p2Text fits within the container.")
			}
			setP2Text(computedLength > maxLength ? maxLength : null)
		}

		// For venueName:
		if (venueNameRef.current) {
			const computedLength = venueNameRef.current.getComputedTextLength()
			const ctm = venueNameRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = venueLength / scaleX
			setVenueMax(maxLength)
			const pixelLength = computedLength * scaleX
			if (pixelLength > venueLength) {
				console.log(
					"venueName text is too long. It spans",
					pixelLength,
					"pixels, but container is only",
					venueLength,
					"pixels wide."
				)
			} else {
				console.log("venueName text fits within the container.")
			}
			setVenueNameText(computedLength > maxLength ? maxLength : null)
		}

		// For compName:
		if (compNameRef.current) {
			const computedLength = compNameRef.current.getComputedTextLength()
			const ctm = compNameRef.current.getScreenCTM()
			const scaleX = ctm.a
			const maxLength = compLength / scaleX
			setCompMax(maxLength)
			const pixelLength = computedLength * scaleX
			if (pixelLength > compLength) {
				console.log(
					"compName text is too long. It spans",
					pixelLength,
					"pixels, but container is only",
					compLength,
					"pixels wide."
				)
			} else {
				console.log("compName text fits within the container.")
			}
			setCompNameText(computedLength > maxLength ? maxLength : null)
		}
	}

	// Trigger text length calculations when dependencies change.
	useLayoutEffect(() => {
		calculateTextLength()
	}, [stats, challenge, matchId, compId])

	const calculateProgress = () => {
		if (!stats?.timer || !stats?.totalTime) return 0
		return (stats.timer / stats.totalTime) * 100 // Calculate progress as a percentage
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 2"
			viewBox="0 0 578.3 182"
		>
			<defs>
				<linearGradient
					id="b"
					x1={17221.78}
					x2={15106.53}
					y1={-6330.99}
					y2={-7664.15}
					gradientTransform="matrix(.07 0 0 -.07 -713.12 -398.16)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0.24} stopColor="#f8f5f5" />
					<stop offset={0.77} stopColor="#bbb" />
				</linearGradient>
				<clipPath id="a">
					<path
						d="M0 32.59h568.31V182H0z"
						style={{ fill: "none" }}
					/>
				</clipPath>
			</defs>
			<g data-name="Layer 1">
				<g data-name="Asset 2 1" style={{ clipPath: "url(#a)" }}>
					<g data-name="Layer 2">
						<g data-name="Layer 1-2">
							<path
								d="M58.01 35.88c.34-.97.99-1.8 1.83-2.39.83-.59 1.85-.9 2.86-.9h500.65c.79 0 1.58.2 2.3.56.7.36 1.33.9 1.78 1.56.45.65.74 1.42.86 2.21.11.79 0 1.6-.27 2.34l-39.4 107.67c-.36.95-.99 1.78-1.83 2.37-.83.59-1.83.9-2.84.9H23.78c-.79 0-1.58-.2-2.3-.56-.7-.36-1.31-.9-1.78-1.56-.45-.65-.74-1.4-.86-2.21-.11-.79 0-1.6.25-2.34L58.01 35.88ZM14.58 159.8c.11-.32.23-.61.32-.88s.2-.54.32-.83c.14-.29.29-.59.47-.86s.41-.52.63-.77c.23-.23.5-.45.77-.63s.56-.34.86-.45.61-.23.92-.29c.32-.07.65-.09.99-.09h500.58c.34 0 .65.02.99.09.34.07.63.16.92.29.29.14.59.27.86.45s.52.38.77.63c.23.23.5.54.72.9s.43.77.54 1.19c.16.43.2.86.23 1.26s-.05.79-.07 1.1l-1.94 5.27-1.94 5.27-.74 2.03-.74 2.03c-.72 1.94-2.01 3.56-3.63 4.71s-3.61 1.78-5.7 1.78H14.09c-3.67 0-6.22-3.65-4.98-7.1l5.45-15.12h.02Z"
								style={{ fill: "#1c1c1c" }}
							/>
							<path
								d="M478.14 179.99v-23.4h41.63c2.84 0 4.8 2.82 3.83 5.48-1.4 3.79-3.25 8.81-4.62 12.6-1.19 3.27-4.33 5.43-7.8 5.43l-33.02-.11h-.02Z"
								style={{ fill: "#42d70e" }}
							/>
							<path
								d="M478.14 156.6V180h-85.11v-23.4"
								data-name="progress bar"
								style={{ fill: "#fff" }}
							/>
							<path
								d="M393.32 34.98c.07-.16.16-.29.29-.41.14-.09.32-.16.47-.16h91.19c.14 0 .27.02.38.09.11.07.23.16.29.27s.14.25.14.38 0 .27-.05.41l-42.58 112.81c-.07.16-.16.29-.32.38-.14.09-.29.14-.47.14h-90.7c-.14 0-.27-.02-.38-.09-.11-.07-.23-.16-.29-.25-.07-.11-.14-.23-.14-.36s0-.27.05-.38L393.28 35h.02v-.02Z"
								data-name="Vector 5"
								style={{ fill: "url(#b)" }}
							/>
						</g>
						<g data-name="Recognition span DETECTION">
							<path
								d="m489.83 173.16 1.4-8.43h5.68l-.25 1.47h-3.9l-.34 2.01h3.61l-.25 1.47h-3.61l-.34 2.01h3.92l-.25 1.47h-5.7l.02.02Z"
								data-name="Vector 8"
								style={{ fill: "#fff" }}
							/>
							<path
								d="m499.95 164.74 1.22 2.86h.07l2.19-2.86h2.07l-3.27 4.21 1.92 4.21h-2.01l-1.24-2.88h-.07l-2.21 2.88h-2.07l3.36-4.21-1.89-4.21h1.96-.02Z"
								data-name="Vector 9"
								style={{ fill: "#fff" }}
							/>
							<path
								d="m506.04 166.2.25-1.47h6.92l-.25 1.47h-2.59l-1.15 6.96h-1.76l1.15-6.96h-2.57Z"
								data-name="Vector 10"
								style={{ fill: "#fff" }}
							/>
						</g>
					</g>
				</g>
				<path
					d="M67.44 4.8c.11-.32.23-.61.32-.88s.2-.54.32-.83c.14-.29.29-.59.47-.86s.41-.52.63-.77c.23-.23.5-.45.77-.63s.56-.34.86-.45c.29-.11.61-.23.92-.29.32-.07.65-.09.99-.09h500.56c.34 0 .65 0 .99.09.34.07.63.16.92.29.29.14.59.27.86.45s.52.38.77.63c.23.23.5.54.72.9s.43.77.54 1.19c.16.43.2.86.23 1.26s-.05.79-.07 1.1l-1.94 5.27-1.94 5.27-.74 2.03-.74 2.03c-.72 1.94-2.01 3.56-3.63 4.71s-3.61 1.78-5.7 1.78H66.94c-3.67 0-6.22-3.65-4.98-7.1l5.45-15.12h.02Z"
					style={{ fill: "#1c1c1c" }}
				/>
				<path
					d="m51.59 53.62 2.91-8.07"
					style={{ fill: "#1c1c1c", isolation: "isolate", opacity: 0.25 }}
				/>
				<path
					d="m560.58 59.59 7.73-21.23-7.73 21.23Z"
					style={{ fill: "none" }}
				/>
				<path
					d="m322.87 159.87-5.84 16.5"
					data-name="Vector 7"
					style={{
						fill: "none",
						stroke: "#c2c2c2",
						strokeLinecap: "round",
						strokeMiterlimit: 10,
					}}
				/>
				<path
					d="m311.03 5.34-5.84 16.5"
					data-name="Vector 7-2"
					style={{
						fill: "none",
						stroke: "#c2c2c2",
						strokeLinecap: "round",
						strokeMiterlimit: 10,
					}}
				/>
				<path
					d="m204.72 159.06-5.84 16.5"
					data-name="Vector 7-3"
					style={{
						fill: "none",
						stroke: "#c2c2c2",
						strokeLinecap: "round",
						strokeMiterlimit: 10,
					}}
				/>
			</g>

			<text
				ref={venueNameRef}
				transform="translate(187, 18)"
				style={{
					isolation: "isolate",
					fontSize: 14,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
				textLength={venueNameText}
				lengthAdjust="spacingAndGlyphs"
				id="venueName"
			>
				{cdata.break_type !== undefined ? `${cdata?.break_type} breaks` : stats[0]?.venuename}
			</text>
			<text
				ref={compNameRef}
				data-name="tournament name"
				transform="translate(440, 18)"
				style={{
					isolation: "isolate",
					fontSize: 14,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
				id="compName"
				textLength={compNameText}
				lengthAdjust="spacingAndGlyphs"
			>
				{challenge ? cdata?.discipline : stats[0]?.compname || ""}
			</text>
			<text
				ref={timezoneRef}
				transform="translate(105, 173)"
				style={{
					isolation: "isolate",
					fontSize: 14,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
				id="timezone"
				textLength={timezoneText}
				lengthAdjust="spacingAndGlyphs"
			>
				{challenge ? cdata?.race_length : stats[0]?.timezone}
			</text>
			<text
				ref={framesRef}
				data-name="race to"
				transform="translate(260, 173)"
				style={{
					isolation: "isolate",
					fontSize: 14,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
				id="frames"
				textLength={framesText}
				lengthAdjust="spacingAndGlyphs"
			>
				{challenge === "true" || challenge === true
					? stats?.raceLength
					: stats[0]?.matchformat === "Play 0"
						? `Played ${framesLeft}`
						: ""}
			</text>
			<text
				id="Timer"

				style={{
					isolation: 'isolate',
					fontSize: 18,
					fill: "red",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAnchor: "middle",
				}}
				transform='matrix(.7 0 0 1 353 175)'
			>
				<tspan x={0} y={0}>
					{challenge === 'true' || challenge === true ? timeText : '0:00'}
				</tspan>
			</text>
			<text
				ref={homeTeamLabelRef}
				id="p1Text"
				data-name="home name"
				transform="translate(214, 72)"
				style={{
					isolation: "isolate",
					fontSize: 26,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
				textLength={p1Text}
				lengthAdjust="spacingAndGlyphs"
			>
				{challenge ? cdata?.homeTeam?.name : stats[0]?.hometeamlabel}
			</text>
			<text
				ref={awayTeamLabelRef}
				lengthAdjust="spacingAndGlyphs"
				id="awayTeamLabel"
				data-name="away name"
				transform="translate(192, 132)"
				style={{
					isolation: "isolate",
					fontSize: 26,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
				textLength={p2Text}
			>
				{challenge ? cdata?.awayTeam?.name : stats[0]?.awayteamlabel}
			</text>
			<text
				ref={p1FrameRef}
				id="homescore"
				transform="translate(425, 75)"
				style={{
					isolation: "isolate",
					fontSize: 36,
					fill: "#000",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
			>
				{challenge ? homeTeamScore : stats[0]?.homescore}
			</text>
			<text
				ref={p2FrameRef}
				id="awayscore"
				transform="translate(403, 135)"
				style={{
					isolation: "isolate",
					fontSize: 36,
					fill: "#000",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
			>
				{challenge ? awayTeamScore : stats[0]?.awayscore}
			</text>
			<text
				ref={p2ScoreRef}
				id="away_points"
				data-name="away points"
				transform="translate(493, 135)"
				style={{
					isolation: "isolate",
					fontSize: 36,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
			>
				{stats[0]?.awayscorepoints || ""}
			</text>
			<text
				ref={p1ScoreRef}
				id="home_points"
				data-name="home points"
				transform="translate(515, 75)"
				style={{
					isolation: "isolate",
					fontSize: 36,
					fill: "#fff",
					fontFamily: "Roboto",
					fontWeight: "bold",
					textAlign: "center",
					textAnchor: "middle",
				}}
			>
				{stats[0]?.homescorepoints || ""}
			</text>
			<foreignObject x='393' y='156.5' width='85' height='23'>
				<div
					style={{
						width: '85px',
						height: '23px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						overflow: 'visible',
						position: 'relative',
						zIndex: 1000
					}}
				>
					<ProgressBar
						completed={time ? (time / progressBarPercentage) * 100 : 0}
						customLabel=' '
						labelSize="30"
						labelColor="#fff"
						labelAlignment="center"
						height="23px"
						width="85px"
						borderRadius="2"
						baseBgColor="#fff"
						bgColor="red"
						isLabelVisible={true}
						transitionDuration="1s"
						transitionTimingFunction="ease-in-out"
						animateOnRender={true}
						dir='ltr'
						isIndeterminate={false}
					/>
				</div>
			</foreignObject>
		</svg>
	)
}

export { SvgRmu }
